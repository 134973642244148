import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../../assets/img/project1.png";
import projImg2 from "../../assets/img/project2.png";
import projImg3 from "../../assets/img/project3.png";
import projImg4 from "../../assets/img/project4.png";
import projImg5 from "../../assets/img/project5.png";
import projImg6 from "../../assets/img/project6.png";
import projImg7 from "../../assets/img/newproject.png";
import colorSharp2 from "../../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';


export const Projects = () => {

  const projects = [
    {
      title: "Car Dealership Online",
      description: "Design & Development",
      language: ["Html - ", "Css - ","JavaScript - ","Sql","PHP","Hostinger"],
      url: "https://a5-pdm.com/" ,
      imgUrl: projImg7,
    },
    {
      title: "Personal LinkTree",
      description: "Design & Animation",
      language: ["Html - ", "Css - ","JavaScript - ","FireBase"],
      url: "https://linktree-wael.web.app" ,
      imgUrl: projImg6,
    },
    {
      title: "Valentine RP",
      description: "Design & Development",
      language: ["Html - ", "Css - ","Bootstrap - ","JavaScript - ","TypeScript - ","API - ","Figma - ","FireBase"],
      url: "https://valentine-rp.com" ,
      imgUrl: projImg1,
    },
    {
      title: "E-Commerce Senior Porject",
      description: "E-Commerce Main Page",
      language: ["Html - ", "Css - ","Bootstrap - ","JavaScript - ","PHP - ","SQL - ","API - ","000WebhHost"],
      url: "https://ajwwebsite.000webhostapp.com" ,
      imgUrl: projImg2,
    },
    {
      title: "E-Commerce Senior Porject Dashboard",
      description: "Admin Dashboard",
      language: ["Html - ", "Css - ","Bootstrap - ","JavaScript - ","PHP - ","SQL - ","API - ","000WebhHost"],
      url: "https://ajwwebsite.000webhostapp.com/admin/admin_login.php" ,
      imgUrl: projImg3,
    },
    {
      title: "Travel Shop",
      description: "UI Design for a Travel Shop",
      language: ["Html - ", "Css - ","SASS - ","Figma - " ,"GIT"],
      url: "https://tabz0012.github.io/travel-website.github.io/" ,
      imgUrl: projImg4,
    },
    {
      title: "First Portfolio",
      description: "Personal Portfolio",
      language: ["Html - ", "Css - ","Figma - " ,"GIT"],
      url: "https://tabz0012.github.io/Portfolio.github.io/" ,
      imgUrl: projImg5,
    },
   
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility partialVisibility={true}>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>Each project is a canvas where I blend creativity, technology, and passion to craft unique and impactful solutions. With every line of code, every pixel placed, I aim not just for functionality, but for experiences that resonate and leave a lasting impression. Explore my portfolio, and you'll find a collection of projects that reflect my commitment to excellence and innovation.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="img"></img>
    </section>
  )
}
