import React, { useEffect, useState } from "react";
import './contact.css';

const StatusPopup = ({ status, onClose }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        if (status.message) {
            const timer = setTimeout(() => {
                setVisible(false);
                onClose(); // Close the popup after 5 seconds
            }, 2000);

            return () => {
                clearTimeout(timer); // Clear the timer when the component unmounts or when status.message changes
            };
        }
    }, [status.message, onClose]);

    if (!status.message || !visible) {
        // If there's no message or it's not visible, return null to hide the component
        return null;
    }

    return (
        <div className="popup-overlay">
            <div className={`popup-box ${status.success ? "alert-success" : "alert-danger"}`}>
                {status.success ? (
                    <div className="confirmation-animation">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                        </svg>
                    </div>
                ) : (
                    <div className="error-animation">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                            <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                        </svg>
                    </div>
                )}
                <div className="popup-content">
                    {status.message}
                </div>
            </div>
        </div>
    );
};

export default StatusPopup;
