import { Col, Row } from "react-bootstrap";
import { FaLink } from 'react-icons/fa';
export const ProjectCard = ({ title, description, imgUrl, language, url }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
  <img src={imgUrl} alt={title} className="img-responsive" style={{ maxWidth: '100%' }} />
  <div className="proj-txtx">
    <h4>{title}</h4>
    <span>{description}</span>
    <Row className="language">
      {language}
    </Row>
    <a href={url} target="_blank"><FaLink /></a>
  </div>
</div>


    </Col>
  )
}
