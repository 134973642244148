import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "../contact/MailchimpForm";
import logo from '../../assets/img/logo.gif';
import navIcon1 from "../../assets/img/nav-icon1.svg";
import navIcon2 from "../../assets/img/nav-icon2.svg";
import navIcon3 from "../../assets/img/nav-icon3.svg";
import { Bio } from '../../data/constants';

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
            <img className="img-fluid" src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href={Bio.linkedin}><img src={navIcon1} alt="Icon" /></a>
              <a href={Bio.github}><img src={navIcon2} alt="Icon" /></a>
              <a href={Bio.insta}><img src={navIcon3} alt="Icon" /></a>
            </div>
            <p>Copyright {currentYear}. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
