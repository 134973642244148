import React, { useState, useMemo } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import contactImg from "../../assets/img/contact-img.svg";
import "animate.css";
import './contact.css';
import TrackVisibility from "react-on-screen";
import StatusPopup from "./StatusPopup";
import emailjs from "emailjs-com";

export const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  // Define formInitialDetails
  const formInitialDetails = useMemo(
    () => ({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    }),
    []
  );

  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Send");
  const [status, setStatus] = useState({});
  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  const onFirstNameChange = (e) => {
    const { value } = e.target;
    setFormDetails((prevFormDetails) => ({
      ...prevFormDetails,
      firstName: value,
    }));
  };
  
  const onLastNameChange = (e) => {
    const { value } = e.target;
    setFormDetails((prevFormDetails) => ({
      ...prevFormDetails,
      lastName: value,
    }));
  };
  
  const onEmailChange = (e) => {
    const { value } = e.target;
    setFormDetails((prevFormDetails) => ({
      ...prevFormDetails,
      email: value,
    }));
  };
  
  const onPhoneChange = (e) => {
    const { value } = e.target;
    setFormDetails((prevFormDetails) => ({
      ...prevFormDetails,
      phone: value,
    }));
  };
  
  const onMessageChange = (e) => {
    const { value } = e.target;
    setFormDetails((prevFormDetails) => ({
      ...prevFormDetails,
      message: value,
    }));
  };
  


  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Sending...");
    console.log("Form Details after sending:", formDetails);
    // Validation for required fields
    const requiredFields = ["firstName", "lastName", "email", "phone", "message"];
    let isValid = true;
    const newErrors = {};

    for (const field of requiredFields) {
      if (!formDetails[field]) {
        isValid = false;
        newErrors[field] = `Please enter your ${field === "firstName" ? "first name" : field}`;
      } else {
        newErrors[field] = ""; // Clear the error message if the field is not empty
      }
    }

    setErrors(newErrors);

    if (!isValid) {
      setButtonText("Send");
      return; // Prevent form submission if validation fails
    }

    // Send the email using Email.js
    emailjs
      .sendForm("service_a0ucu92", "template_o6exk0l", e.target, "AaqKkCSHKVhhDe06u")
      .then(
        (result) => {
          // Set status to show the success message
          setStatus({ success: true, message: "Message sent successfully" });

          // Show the popup
          setShowPopup(true);
          // Clear the form
          setFormDetails(formInitialDetails);
          setButtonText("Send");
        },
        (error) => {
          console.error("Email sending failed:", error.text);
          setStatus({ success: false, message: "Email sending failed" });
          // Show the popup
          setShowPopup(true);
          // Clear the form
          setFormDetails(formInitialDetails);
          setButtonText("Send");
          console.log("Form Details after sending (error):", formDetails);
        }

      );

  };

  return (

    <section className="contact" id="connect">

      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <img
                  className={isVisible ? "animate__animated animate__zoomIn" : ""}
                  src={contactImg}
                  alt="Contact Us"
                />
              )}
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Get In Touch</h2>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col size={12} sm={6} className="px-1">
                        <div className={`form-group ${errors.firstName ? "has-error" : ""}`}>
                          <input
                            type="text"
                            name="firstName"
                            value={formDetails.firstName}
                            placeholder={`${errors.firstName ? "Please enter your first name" : "First Name"
                              }`}
                            onChange={onFirstNameChange}
                          />
                        </div>
                      </Col>



                      <Col size={12} sm={6} className="px-1">
                        <div className={`form-group ${errors.lastName ? "has-error" : ""}`}>
                          <input
                            type="text"
                            value={formDetails.lastName}
                            name="lastName" // Add the 'name' attribute to identify the field
                            placeholder={`${errors.lastName ? "Please enter your last name" : "Last Name"}`}
                            onChange={onLastNameChange}
                          />
                        </div>
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <div className={`form-group ${errors.email ? "has-error" : ""}`}>
                          <input
                            type="email"
                            name="email"
                            value={formDetails.email}
                            placeholder={`${errors.email ? "Please enter your email address" : "Email Address"}`}
                            onChange={onEmailChange}
                          />

                        </div>
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <div className={`form-group ${errors.phone ? "has-error" : ""}`}>
                          <input
                            type="tel"
                            name="phone"
                            value={formDetails.phone}
                            placeholder={`${errors.phone ? "Please enter your phone number" : "Phone Number"}`}
                            onChange={onPhoneChange}
                          />

                        </div>
                      </Col>
                      <Col size={12} className="px-1">
                        <div className={`form-group ${errors.message ? "has-error" : ""}`}>
                          <textarea
                            rows="6"
                            name="message"
                            value={formDetails.message}
                            placeholder={`${errors.message ? "Please enter your message" : "Message"}`}
                            onChange={onMessageChange}
                          ></textarea>

                        </div>
                        <Button type="submit">
                          <span>{buttonText}</span>
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <StatusPopup status={status} onClose={() => setShowPopup(false)} />
    </section>
  );
};
